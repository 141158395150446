import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this lesson, we'll learn some of the key concepts behind clustering.`}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <p>{`After completing this lesson, you'll be able to ...`}</p>
    <ul>
      <li parentName="ul">{`describe the difference between a centroid and a medoid`}</li>
      <li parentName="ul">{`calculate a medoid`}</li>
      <li parentName="ul">{`calculate a centroid`}</li>
    </ul>
    <h1 {...{
      "id": "prerequisites",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#prerequisites",
        "aria-label": "prerequisites permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prerequisites`}</h1>
    <p>{`Before you start, ensure you're ...`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/vector-basics"
        }}>{`comfortable performing basic vector operations`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/distance-and-similarity"
        }}>{`familiar with common distance metrics`}</a></li>
    </ul>
    {
      /* # Background
      ??? */
    }
    <h1 {...{
      "id": "clustering",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#clustering",
        "aria-label": "clustering permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Clustering`}</h1>
    <p>{`Clustering is a semi-supervised or unsupervised technique for partitioning data in a set of 2 or more `}<strong parentName="p">{`clusters`}</strong>{` (groups).  It is often use as an exploratory technique in data science, but it can also be used as a form of classification (i.e., assigning one or more labels to each datapoint).`}</p>
    <h2 {...{
      "id": "semi-supervised-clustering",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#semi-supervised-clustering",
        "aria-label": "semi supervised clustering permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Semi-supervised clustering`}</h2>
    <p>{`In `}<strong parentName="p">{`semi-supervised`}</strong>{` clustering, you create a similarity-based classifier with a small portion of labeled datapoints to serve as `}<em parentName="p">{`seeds`}</em>{` to various clusters.  `}</p>
    <p>{`Imagine, for example, you have created a set of word representations and want to group them according to whether or not the word can behave as a noun.  Maybe you've identified 10 words that can (cluster A) and another 10 that can't (cluster B).  The rest of your data is unlabeled.  Your goal is then to assign the remaining datapoints (word representations) to one of these two clusters by determining whether an unlabeled word representation is more similar to cluster A or cluster B.`}</p>
    <p>{`Examples of `}<strong parentName="p">{`semi-supervised`}</strong>{` clustering algorithms include `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/K-nearest_neighbors_algorithm",
        "target": "_self",
        "rel": "nofollow"
      }}><em parentName="a">{`k`}</em>{` nearest neighbors (_k_NN)`}</a>{`
and the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Nearest_centroid_classifier",
        "target": "_self",
        "rel": "nofollow"
      }}>{`nearest centroid classifier (Rocchio classifier)`}</a>{`.  Many of the unspervised clustering algorithms have variations that work in semi-supervised settings.`}</p>
    <h2 {...{
      "id": "unsupervised-clustering",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#unsupervised-clustering",
        "aria-label": "unsupervised clustering permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Unsupervised clustering`}</h2>
    <p>{`In `}<strong parentName="p">{`unsupervised`}</strong>{` clustering, you have no labeled data. Your aim is simply to partition the data according to some similarity or distance function, but you may or `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Determining_the_number_of_clusters_in_a_data_set",
        "target": "_self",
        "rel": "nofollow"
      }}>{`may not know`}</a>{` how many clusters you want at the end of this process.`}</p>
    <p>{`Examples of `}<strong parentName="p">{`unsupervised`}</strong>{` clustering algorithms include `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Hierarchical_clustering",
        "target": "_self",
        "rel": "nofollow"
      }}>{`hierarchical agglomerative clustering (HAC)`}</a>{`, `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/DBSCAN",
        "target": "_self",
        "rel": "nofollow"
      }}>{`DBSCAN`}</a>{`, `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/K-means_clustering",
        "target": "_self",
        "rel": "nofollow"
      }}><em parentName="a">{`k`}</em>{`-means`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/K-medoids",
        "target": "_self",
        "rel": "nofollow"
      }}><em parentName="a">{`k`}</em>{`-medoids`}</a>{`.`}</p>
    <h1 {...{
      "id": "finding-the-middle-of-a-cluster",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#finding-the-middle-of-a-cluster",
        "aria-label": "finding the middle of a cluster permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Finding the middle of a cluster`}</h1>
    <p>{`Many approaches to clustering rely on measuring and comparing distance to the "middle" of various clusters.  There are two primary notions of center or middle that we'll discuss: the `}<strong parentName="p">{`centroid`}</strong>{` and `}<strong parentName="p">{`medoid`}</strong>{`.`}</p>
    <h2 {...{
      "id": "centroid",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#centroid",
        "aria-label": "centroid permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Centroid`}</h2>
    <p>{`Given a group or matrix of vectors `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`X`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{X}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6861em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`X`}</span></span></span></span></span>{`, the `}<strong parentName="p">{`centroid`}</strong>{` is the average vector from this group:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`c`}</mi><mi parentName="msub" {...{
                      "mathvariant": "bold"
                    }}>{`X`}</mi></msub><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∣`}</mi><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`X`}</mi><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∣`}</mi></mrow></mfrac><munder parentName="mrow"><mo parentName="munder">{`∑`}</mo><mrow parentName="munder"><msub parentName="mrow"><mi parentName="msub" {...{
                          "mathvariant": "bold"
                        }}>{`x`}</mi><mi parentName="msub">{`i`}</mi></msub><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`X`}</mi></mrow></munder><msub parentName="mrow"><mi parentName="msub" {...{
                      "mathvariant": "bold"
                    }}>{`x`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`c_{\\mathbf{X}} = \\frac{1}{\\vert \\mathbf{X} \\vert}\\sum_{\\mathbf{x}_{i} \\in \\mathbf{X}} \\mathbf{x}_{i} `}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.5806em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`c`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3303em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathbf mtight"
                              }}>{`X`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.7178em",
                  "verticalAlign": "-1.3964em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3214em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`∣`}</span><span parentName="span" {...{
                              "className": "mord mathbf"
                            }}>{`X`}</span><span parentName="span" {...{
                              "className": "mord"
                            }}>{`∣`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.936em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop op-limits"
              }}><span parentName="span" {...{
                  "className": "vlist-t vlist-t2"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "1.05em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-1.8537em",
                          "marginLeft": "0em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3.05em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "sizing reset-size6 size3 mtight"
                        }}><span parentName="span" {...{
                            "className": "mord mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathbf mtight"
                              }}>{`x`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t vlist-t2"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.3281em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.357em",
                                          "marginLeft": "0em",
                                          "marginRight": "0.0714em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.5em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size3 size1 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mathnormal mtight"
                                            }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                                      "className": "vlist-s"
                                    }}>{`​`}</span></span><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.143em"
                                      }
                                    }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                              "className": "mrel mtight"
                            }}>{`∈`}</span><span parentName="span" {...{
                              "className": "mord mathbf mtight"
                            }}>{`X`}</span></span></span></span><span parentName="span" {...{
                        "style": {
                          "top": "-3.05em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3.05em"
                          }
                        }}></span><span parentName="span"><span parentName="span" {...{
                            "className": "mop op-symbol large-op"
                          }}>{`∑`}</span></span></span></span><span parentName="span" {...{
                      "className": "vlist-s"
                    }}>{`​`}</span></span><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "1.3964em"
                      }
                    }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathbf"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`In other words, the centroid is the center of this group of vectors.  While it's possible the centroid is one of the vectors in `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`X`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{X}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6861em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`X`}</span></span></span></span></span>{`, that's uncommon.`}</p>
    <p>{`Let's work through an example...`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mi parentName="mstyle" {...{
                          "mathvariant": "bold"
                        }}>{`X`}</mi></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`[`}</mo><mtable parentName="mrow" {...{
                              "rowspacing": "0.16em",
                              "columnalign": "center center center",
                              "columnspacing": "1em"
                            }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`4`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`2`}</mn></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`11`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`1`}</mn></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`9`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`]`}</mo></mrow></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><msub parentName="mstyle"><mi parentName="msub">{`c`}</mi><mi parentName="msub" {...{
                            "mathvariant": "bold"
                          }}>{`X`}</mi></msub></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`[`}</mo><mtable parentName="mrow" {...{
                              "rowspacing": "0.16em",
                              "columnalign": "center center center",
                              "columnspacing": "1em"
                            }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mfrac parentName="mstyle"><mrow parentName="mfrac"><mo parentName="mrow" {...{
                                          "stretchy": "false"
                                        }}>{`(`}</mo><mn parentName="mrow">{`4`}</mn><mo parentName="mrow">{`+`}</mo><mn parentName="mrow">{`11`}</mn><mo parentName="mrow">{`+`}</mo><mn parentName="mrow">{`9`}</mn><mo parentName="mrow" {...{
                                          "stretchy": "false"
                                        }}>{`)`}</mo></mrow><mn parentName="mfrac">{`3`}</mn></mfrac></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mfrac parentName="mstyle"><mrow parentName="mfrac"><mo parentName="mrow" {...{
                                          "stretchy": "false"
                                        }}>{`(`}</mo><mn parentName="mrow">{`3`}</mn><mo parentName="mrow">{`+`}</mo><mn parentName="mrow">{`3`}</mn><mo parentName="mrow">{`+`}</mo><mn parentName="mrow">{`3`}</mn><mo parentName="mrow" {...{
                                          "stretchy": "false"
                                        }}>{`)`}</mo></mrow><mn parentName="mfrac">{`3`}</mn></mfrac></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mfrac parentName="mstyle"><mrow parentName="mfrac"><mo parentName="mrow" {...{
                                          "stretchy": "false"
                                        }}>{`(`}</mo><mn parentName="mrow">{`2`}</mn><mo parentName="mrow">{`+`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow">{`+`}</mo><mn parentName="mrow">{`3`}</mn><mo parentName="mrow" {...{
                                          "stretchy": "false"
                                        }}>{`)`}</mo></mrow><mn parentName="mfrac">{`3`}</mn></mfrac></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`]`}</mo></mrow></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><msub parentName="mstyle"><mi parentName="msub">{`c`}</mi><mi parentName="msub" {...{
                            "mathvariant": "bold"
                          }}>{`X`}</mi></msub></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`[`}</mo><mtable parentName="mrow" {...{
                              "rowspacing": "0.16em",
                              "columnalign": "center center center",
                              "columnspacing": "1em"
                            }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`8`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`2`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`]`}</mo></mrow></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{aligned}

\\mathbf{X} &= 
\\begin{bmatrix}
4  & 3 & 2 \\\\
11 & 3 & 1 \\\\
9  & 3 & 3 \\\\
\\end{bmatrix}

\\\\[2em]

c_{\\mathbf{X}} &= \\begin{bmatrix}
\\frac{(4 + 11 + 9)}{3} & \\frac{(3 + 3 + 3)}{3} & \\frac{(2 + 1 + 3)}{3} \\\\
\\end{bmatrix} 

\\\\[2em]

c_{\\mathbf{X}} &= \\begin{bmatrix}
8 & 3 & 2 \\\\
\\end{bmatrix}
\\end{aligned}

`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "10.03em",
                  "verticalAlign": "-4.765em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "5.265em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-7.265em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathbf"
                              }}>{`X`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.455em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`c`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3303em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathbf mtight"
                                              }}>{`X`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "0.055em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`c`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3303em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathbf mtight"
                                              }}>{`X`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "4.765em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "col-align-l"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "5.265em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-7.265em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "minner"
                              }}><span parentName="span" {...{
                                  "className": "mopen"
                                }}><span parentName="span" {...{
                                    "className": "delimsizing mult"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-t vlist-t2"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "2.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "style": {
                                              "top": "-2.25em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "delimsizinginner delim-size4"
                                            }}><span parentName="span">{`⎣`}</span></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-3.397em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "style": {
                                                "height": "0.016em",
                                                "width": "0.6667em"
                                              }
                                            }}><svg parentName="span" {...{
                                                "xmlns": "http://www.w3.org/2000/svg",
                                                "width": "0.6667em",
                                                "height": "0.016em",
                                                "style": {
                                                  "width": "0.6667em"
                                                },
                                                "viewBox": "0 0 666.67 16",
                                                "preserveAspectRatio": "xMinYMin"
                                              }}><path parentName="svg" {...{
                                                  "d": "M319 0 H403 V16 H319z M319 0 H403 V16 H319z"
                                                }}></path></svg></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-4.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "delimsizinginner delim-size4"
                                            }}><span parentName="span">{`⎡`}</span></span></span></span><span parentName="span" {...{
                                          "className": "vlist-s"
                                        }}>{`​`}</span></span><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "1.55em"
                                          }
                                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mtable"
                                  }}><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "2.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-4.21em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`4`}</span></span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`11`}</span></span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-1.81em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`9`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "1.55em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "2.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-4.21em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`3`}</span></span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`3`}</span></span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-1.81em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`3`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "1.55em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "2.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-4.21em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`2`}</span></span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`1`}</span></span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-1.81em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`3`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "1.55em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose"
                                }}><span parentName="span" {...{
                                    "className": "delimsizing mult"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-t vlist-t2"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "2.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "style": {
                                              "top": "-2.25em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "delimsizinginner delim-size4"
                                            }}><span parentName="span">{`⎦`}</span></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-3.397em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "style": {
                                                "height": "0.016em",
                                                "width": "0.6667em"
                                              }
                                            }}><svg parentName="span" {...{
                                                "xmlns": "http://www.w3.org/2000/svg",
                                                "width": "0.6667em",
                                                "height": "0.016em",
                                                "style": {
                                                  "width": "0.6667em"
                                                },
                                                "viewBox": "0 0 666.67 16",
                                                "preserveAspectRatio": "xMinYMin"
                                              }}><path parentName="svg" {...{
                                                  "d": "M263 0 H347 V16 H263z M263 0 H347 V16 H263z"
                                                }}></path></svg></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-4.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "delimsizinginner delim-size4"
                                            }}><span parentName="span">{`⎤`}</span></span></span></span><span parentName="span" {...{
                                          "className": "vlist-s"
                                        }}>{`​`}</span></span><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "1.55em"
                                          }
                                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.455em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "minner"
                              }}><span parentName="span" {...{
                                  "className": "mopen delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size2"
                                  }}>{`[`}</span></span><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mtable"
                                  }}><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.935em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-2.935em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3.01em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}><span parentName="span" {...{
                                                    "className": "mopen nulldelimiter"
                                                  }}></span><span parentName="span" {...{
                                                    "className": "mfrac"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist-t vlist-t2"
                                                    }}><span parentName="span" {...{
                                                        "className": "vlist-r"
                                                      }}><span parentName="span" {...{
                                                          "className": "vlist",
                                                          "style": {
                                                            "height": "1.01em"
                                                          }
                                                        }}><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-2.655em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "sizing reset-size6 size3 mtight"
                                                            }}><span parentName="span" {...{
                                                                "className": "mord mtight"
                                                              }}><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`3`}</span></span></span></span><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-3.23em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "frac-line",
                                                              "style": {
                                                                "borderBottomWidth": "0.04em"
                                                              }
                                                            }}></span></span><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-3.485em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "sizing reset-size6 size3 mtight"
                                                            }}><span parentName="span" {...{
                                                                "className": "mord mtight"
                                                              }}><span parentName="span" {...{
                                                                  "className": "mopen mtight"
                                                                }}>{`(`}</span><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`4`}</span><span parentName="span" {...{
                                                                  "className": "mbin mtight"
                                                                }}>{`+`}</span><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`11`}</span><span parentName="span" {...{
                                                                  "className": "mbin mtight"
                                                                }}>{`+`}</span><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`9`}</span><span parentName="span" {...{
                                                                  "className": "mclose mtight"
                                                                }}>{`)`}</span></span></span></span></span><span parentName="span" {...{
                                                          "className": "vlist-s"
                                                        }}>{`​`}</span></span><span parentName="span" {...{
                                                        "className": "vlist-r"
                                                      }}><span parentName="span" {...{
                                                          "className": "vlist",
                                                          "style": {
                                                            "height": "0.345em"
                                                          }
                                                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                                    "className": "mclose nulldelimiter"
                                                  }}></span></span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.435em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.935em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-2.935em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3.01em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}><span parentName="span" {...{
                                                    "className": "mopen nulldelimiter"
                                                  }}></span><span parentName="span" {...{
                                                    "className": "mfrac"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist-t vlist-t2"
                                                    }}><span parentName="span" {...{
                                                        "className": "vlist-r"
                                                      }}><span parentName="span" {...{
                                                          "className": "vlist",
                                                          "style": {
                                                            "height": "1.01em"
                                                          }
                                                        }}><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-2.655em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "sizing reset-size6 size3 mtight"
                                                            }}><span parentName="span" {...{
                                                                "className": "mord mtight"
                                                              }}><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`3`}</span></span></span></span><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-3.23em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "frac-line",
                                                              "style": {
                                                                "borderBottomWidth": "0.04em"
                                                              }
                                                            }}></span></span><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-3.485em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "sizing reset-size6 size3 mtight"
                                                            }}><span parentName="span" {...{
                                                                "className": "mord mtight"
                                                              }}><span parentName="span" {...{
                                                                  "className": "mopen mtight"
                                                                }}>{`(`}</span><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`3`}</span><span parentName="span" {...{
                                                                  "className": "mbin mtight"
                                                                }}>{`+`}</span><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`3`}</span><span parentName="span" {...{
                                                                  "className": "mbin mtight"
                                                                }}>{`+`}</span><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`3`}</span><span parentName="span" {...{
                                                                  "className": "mclose mtight"
                                                                }}>{`)`}</span></span></span></span></span><span parentName="span" {...{
                                                          "className": "vlist-s"
                                                        }}>{`​`}</span></span><span parentName="span" {...{
                                                        "className": "vlist-r"
                                                      }}><span parentName="span" {...{
                                                          "className": "vlist",
                                                          "style": {
                                                            "height": "0.345em"
                                                          }
                                                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                                    "className": "mclose nulldelimiter"
                                                  }}></span></span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.435em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.935em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-2.935em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3.01em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}><span parentName="span" {...{
                                                    "className": "mopen nulldelimiter"
                                                  }}></span><span parentName="span" {...{
                                                    "className": "mfrac"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist-t vlist-t2"
                                                    }}><span parentName="span" {...{
                                                        "className": "vlist-r"
                                                      }}><span parentName="span" {...{
                                                          "className": "vlist",
                                                          "style": {
                                                            "height": "1.01em"
                                                          }
                                                        }}><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-2.655em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "sizing reset-size6 size3 mtight"
                                                            }}><span parentName="span" {...{
                                                                "className": "mord mtight"
                                                              }}><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`3`}</span></span></span></span><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-3.23em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "frac-line",
                                                              "style": {
                                                                "borderBottomWidth": "0.04em"
                                                              }
                                                            }}></span></span><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-3.485em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "sizing reset-size6 size3 mtight"
                                                            }}><span parentName="span" {...{
                                                                "className": "mord mtight"
                                                              }}><span parentName="span" {...{
                                                                  "className": "mopen mtight"
                                                                }}>{`(`}</span><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`2`}</span><span parentName="span" {...{
                                                                  "className": "mbin mtight"
                                                                }}>{`+`}</span><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`1`}</span><span parentName="span" {...{
                                                                  "className": "mbin mtight"
                                                                }}>{`+`}</span><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`3`}</span><span parentName="span" {...{
                                                                  "className": "mclose mtight"
                                                                }}>{`)`}</span></span></span></span></span><span parentName="span" {...{
                                                          "className": "vlist-s"
                                                        }}>{`​`}</span></span><span parentName="span" {...{
                                                        "className": "vlist-r"
                                                      }}><span parentName="span" {...{
                                                          "className": "vlist",
                                                          "style": {
                                                            "height": "0.345em"
                                                          }
                                                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                                    "className": "mclose nulldelimiter"
                                                  }}></span></span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.435em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size2"
                                  }}>{`]`}</span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "0.055em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "minner"
                              }}><span parentName="span" {...{
                                  "className": "mopen delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size1"
                                  }}>{`[`}</span></span><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mtable"
                                  }}><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`8`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`3`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`2`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size1"
                                  }}>{`]`}</span></span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "4.765em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p><img parentName="p" {...{
        "src": "/a0491ad77ea81a24cc14dc72360fdf3f/3d-centroid-example.gif",
        "alt": "\"Centroid (visualized).\"",
        "title": "Centroid in relation to other points."
      }}></img></p>
    <p>{`When using vectorized operations with a library such as NumPy, it's easier to decompose this into a column-wise summation and scalar division.`}</p>
    <h2 {...{
      "id": "medoids",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#medoids",
        "aria-label": "medoids permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Medoids`}</h2>
    <p>{`Given a group of vectors `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`X`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{X}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6861em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`X`}</span></span></span></span></span>{`, the `}<strong parentName="p">{`medoid`}</strong>{` of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`X`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{X}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6861em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`X`}</span></span></span></span></span>{` is the vector in `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`X`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{X}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6861em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`X`}</span></span></span></span></span>{` with the `}<strong parentName="p">{`smallest average distance`}</strong>{` with every other vector in `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`X`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{X}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6861em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`X`}</span></span></span></span></span>{` according to some distance function `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`d`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`d`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`d`}</span></span></span></span></span>{` (e.g. Euclidean distance):`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub" {...{
                      "mathvariant": "bold"
                    }}>{`X`}</mi><mtext parentName="msub">{`medoid`}</mtext></msub><mo parentName="mrow">{`=`}</mo><mi parentName="mrow"><munder parentName="mi"><mo parentName="munder"><mi parentName="mo" {...{
                          "mathvariant": "normal"
                        }}>{`arg min`}</mi><mo parentName="mo">{`⁡`}</mo></mo><mrow parentName="munder"><mi parentName="mrow" {...{
                          "mathvariant": "bold"
                        }}>{`v`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow" {...{
                          "mathvariant": "bold"
                        }}>{`X`}</mi></mrow></munder></mi><mfrac parentName="mrow"><mrow parentName="mfrac"><munder parentName="mrow"><mo parentName="munder">{`∑`}</mo><mrow parentName="munder"><msub parentName="mrow"><mi parentName="msub" {...{
                              "mathvariant": "bold"
                            }}>{`x`}</mi><mi parentName="msub">{`i`}</mi></msub><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`X`}</mi></mrow></munder><mi parentName="mrow">{`d`}</mi><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`(`}</mo><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`v`}</mi><mo parentName="mrow" {...{
                        "separator": "true"
                      }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub" {...{
                          "mathvariant": "bold"
                        }}>{`x`}</mi><mi parentName="msub">{`i`}</mi></msub><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`)`}</mo></mrow><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∣`}</mi><mi parentName="mrow">{`X`}</mi><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∣`}</mi></mrow></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{X}_{\\text{medoid}} = \\underset{\\mathbf{v} \\in \\mathbf{X}}{\\argmin}\\frac{\\sum_{\\mathbf{x}_{i} \\in X} d(\\mathbf{v}, \\mathbf{x}_{i})}{\\vert X \\vert}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8361em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathbf"
                }}>{`X`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3361em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord text mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`medoid`}</span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.5079em",
                  "verticalAlign": "-0.9681em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mop op-limits"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.6679em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.1593em",
                            "marginLeft": "0em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathbf mtight",
                                "style": {
                                  "marginRight": "0.01597em"
                                }
                              }}>{`v`}</span><span parentName="span" {...{
                                "className": "mrel mtight"
                              }}>{`∈`}</span><span parentName="span" {...{
                                "className": "mord mathbf mtight"
                              }}>{`X`}</span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span"><span parentName="span" {...{
                              "className": "mop"
                            }}><span parentName="span" {...{
                                "className": "mop"
                              }}><span parentName="span" {...{
                                  "className": "mord mathrm",
                                  "style": {
                                    "marginRight": "0.01389em"
                                  }
                                }}>{`arg`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.1667em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mord mathrm"
                                }}>{`min`}</span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.9681em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.5398em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`∣`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.07847em"
                              }
                            }}>{`X`}</span><span parentName="span" {...{
                              "className": "mord"
                            }}>{`∣`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.7898em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mop"
                            }}><span parentName="span" {...{
                                "className": "mop op-symbol small-op",
                                "style": {
                                  "position": "relative",
                                  "top": "0em"
                                }
                              }}>{`∑`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t vlist-t2"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.1786em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.4003em",
                                          "marginLeft": "0em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathbf mtight"
                                              }}>{`x`}</span><span parentName="span" {...{
                                                "className": "msupsub"
                                              }}><span parentName="span" {...{
                                                  "className": "vlist-t vlist-t2"
                                                }}><span parentName="span" {...{
                                                    "className": "vlist-r"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist",
                                                      "style": {
                                                        "height": "0.3281em"
                                                      }
                                                    }}><span parentName="span" {...{
                                                        "style": {
                                                          "top": "-2.357em",
                                                          "marginLeft": "0em",
                                                          "marginRight": "0.0714em"
                                                        }
                                                      }}><span parentName="span" {...{
                                                          "className": "pstrut",
                                                          "style": {
                                                            "height": "2.5em"
                                                          }
                                                        }}></span><span parentName="span" {...{
                                                          "className": "sizing reset-size3 size1 mtight"
                                                        }}><span parentName="span" {...{
                                                            "className": "mord mtight"
                                                          }}><span parentName="span" {...{
                                                              "className": "mord mathnormal mtight"
                                                            }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                                                      "className": "vlist-s"
                                                    }}>{`​`}</span></span><span parentName="span" {...{
                                                    "className": "vlist-r"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist",
                                                      "style": {
                                                        "height": "0.143em"
                                                      }
                                                    }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                              "className": "mrel mtight"
                                            }}>{`∈`}</span><span parentName="span" {...{
                                              "className": "mord mathnormal mtight",
                                              "style": {
                                                "marginRight": "0.07847em"
                                              }
                                            }}>{`X`}</span></span></span></span></span><span parentName="span" {...{
                                      "className": "vlist-s"
                                    }}>{`​`}</span></span><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.3998em"
                                      }
                                    }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.1667em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`d`}</span><span parentName="span" {...{
                              "className": "mopen"
                            }}>{`(`}</span><span parentName="span" {...{
                              "className": "mord mathbf",
                              "style": {
                                "marginRight": "0.01597em"
                              }
                            }}>{`v`}</span><span parentName="span" {...{
                              "className": "mpunct"
                            }}>{`,`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.1667em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathbf"
                              }}>{`x`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t vlist-t2"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.3117em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.55em",
                                          "marginLeft": "0em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mathnormal mtight"
                                            }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                                      "className": "vlist-s"
                                    }}>{`​`}</span></span><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.15em"
                                      }
                                    }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                              "className": "mclose"
                            }}>{`)`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.936em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span></div>
    <ul>
      <li parentName="ul"><span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`d`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`d`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`d`}</span></span></span></span></span>{` is some distance function.`}</li>
      <li parentName="ul"><span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`arg min`}</mi><mo parentName="mrow">{`⁡`}</mo></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`\\argmin`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.8623em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mop"
                }}><span parentName="span" {...{
                    "className": "mord mathrm",
                    "style": {
                      "marginRight": "0.01389em"
                    }
                  }}>{`arg`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.1667em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathrm"
                  }}>{`min`}</span></span></span></span></span></span>{` finds the `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`v`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`v`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.4306em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`v`}</span></span></span></span></span>{` with the minimum average distance.`}
        <ul parentName="li">
          <li parentName="ul"><span parentName="li" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`v`}</mi></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`v`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.4306em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathnormal",
                      "style": {
                        "marginRight": "0.03588em"
                      }
                    }}>{`v`}</span></span></span></span></span>{` is an actual vector in `}<span parentName="li" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                          "mathvariant": "bold"
                        }}>{`X`}</mi></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`\\mathbf{X}`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.6861em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathbf"
                    }}>{`X`}</span></span></span></span></span></li>
          <li parentName="ul"><span parentName="li" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                          "mathvariant": "normal"
                        }}>{`∣`}</mi><mi parentName="mrow" {...{
                          "mathvariant": "bold"
                        }}>{`X`}</mi><mi parentName="mrow" {...{
                          "mathvariant": "normal"
                        }}>{`∣`}</mi></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`\\vert \\mathbf{X} \\vert`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "1em",
                        "verticalAlign": "-0.25em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}>{`∣`}</span><span parentName="span" {...{
                      "className": "mord mathbf"
                    }}>{`X`}</span><span parentName="span" {...{
                      "className": "mord"
                    }}>{`∣`}</span></span></span></span></span>{` represents the number of rows (vectors) in `}<span parentName="li" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                          "mathvariant": "bold"
                        }}>{`X`}</mi></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`\\mathbf{X}`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.6861em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathbf"
                    }}>{`X`}</span></span></span></span></span></li>
        </ul>
      </li>
    </ul>
    <p>{`In other words, the `}<strong parentName="p">{`medoid`}</strong>{` is the vector in some group that is the most central according to some distance metric `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`d`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`d`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`d`}</span></span></span></span></span>{`.`}</p>
    <p>{`Let's work through an example using Euclidean distance (`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`d`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`d_{2}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`d`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`):`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mi parentName="mstyle" {...{
                          "mathvariant": "bold"
                        }}>{`X`}</mi></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`[`}</mo><mtable parentName="mrow" {...{
                              "rowspacing": "0.16em",
                              "columnalign": "center center center",
                              "columnspacing": "1em"
                            }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`4`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`2`}</mn></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`11`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`1`}</mn></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`9`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`]`}</mo></mrow></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mi parentName="mstyle" {...{
                          "mathvariant": "bold"
                        }}>{`X`}</mi></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`[`}</mo><mtable parentName="mrow" {...{
                              "rowspacing": "0.16em",
                              "columnalign": "center",
                              "columnspacing": "1em"
                            }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mi parentName="mstyle" {...{
                                      "mathvariant": "bold"
                                    }}>{`a`}</mi></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mi parentName="mstyle" {...{
                                      "mathvariant": "bold"
                                    }}>{`b`}</mi></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mi parentName="mstyle" {...{
                                      "mathvariant": "bold"
                                    }}>{`c`}</mi></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`]`}</mo></mrow></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><msub parentName="mstyle"><mi parentName="msub" {...{
                            "mathvariant": "bold"
                          }}>{`X`}</mi><mtext parentName="msub">{`medoid`}</mtext></msub></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mi parentName="mrow"><munder parentName="mi"><mo parentName="munder"><mi parentName="mo" {...{
                                  "mathvariant": "normal"
                                }}>{`arg min`}</mi><mo parentName="mo">{`⁡`}</mo></mo><mrow parentName="munder"><mi parentName="mrow" {...{
                                  "mathvariant": "bold"
                                }}>{`v`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow" {...{
                                  "mathvariant": "bold"
                                }}>{`X`}</mi></mrow></munder></mi><mfrac parentName="mrow"><mrow parentName="mfrac"><munder parentName="mrow"><mo parentName="munder">{`∑`}</mo><mrow parentName="munder"><msub parentName="mrow"><mi parentName="msub" {...{
                                      "mathvariant": "bold"
                                    }}>{`x`}</mi><mi parentName="msub">{`i`}</mi></msub><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow" {...{
                                    "mathvariant": "bold"
                                  }}>{`X`}</mi></mrow></munder><mi parentName="mrow">{`d`}</mi><mo parentName="mrow" {...{
                                "stretchy": "false"
                              }}>{`(`}</mo><mi parentName="mrow" {...{
                                "mathvariant": "bold"
                              }}>{`v`}</mi><mo parentName="mrow" {...{
                                "separator": "true"
                              }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub" {...{
                                  "mathvariant": "bold"
                                }}>{`x`}</mi><mi parentName="msub">{`i`}</mi></msub><mo parentName="mrow" {...{
                                "stretchy": "false"
                              }}>{`)`}</mo></mrow><mrow parentName="mfrac"><mi parentName="mrow" {...{
                                "mathvariant": "normal"
                              }}>{`∣`}</mi><mi parentName="mrow">{`X`}</mi><mi parentName="mrow" {...{
                                "mathvariant": "normal"
                              }}>{`∣`}</mi></mrow></mfrac></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><msub parentName="mstyle"><mi parentName="msub" {...{
                            "mathvariant": "bold"
                          }}>{`X`}</mi><mtext parentName="msub">{`medoid`}</mtext></msub></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mi parentName="mrow"><munder parentName="mi"><mo parentName="munder"><mi parentName="mo" {...{
                                  "mathvariant": "normal"
                                }}>{`arg min`}</mi><mo parentName="mo">{`⁡`}</mo></mo><mrow parentName="munder"><mi parentName="mrow" {...{
                                  "mathvariant": "bold"
                                }}>{`v`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow" {...{
                                  "mathvariant": "bold"
                                }}>{`X`}</mi></mrow></munder></mi><mfrac parentName="mrow"><mrow parentName="mfrac"><msub parentName="mrow"><mi parentName="msub">{`d`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow" {...{
                                "stretchy": "false"
                              }}>{`(`}</mo><mi parentName="mrow" {...{
                                "mathvariant": "bold"
                              }}>{`v`}</mi><mo parentName="mrow" {...{
                                "separator": "true"
                              }}>{`,`}</mo><mi parentName="mrow" {...{
                                "mathvariant": "bold"
                              }}>{`a`}</mi><mo parentName="mrow" {...{
                                "stretchy": "false"
                              }}>{`)`}</mo><mo parentName="mrow">{`+`}</mo><msub parentName="mrow"><mi parentName="msub">{`d`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow" {...{
                                "stretchy": "false"
                              }}>{`(`}</mo><mi parentName="mrow" {...{
                                "mathvariant": "bold"
                              }}>{`v`}</mi><mo parentName="mrow" {...{
                                "separator": "true"
                              }}>{`,`}</mo><mi parentName="mrow" {...{
                                "mathvariant": "bold"
                              }}>{`b`}</mi><mo parentName="mrow" {...{
                                "stretchy": "false"
                              }}>{`)`}</mo><mo parentName="mrow">{`+`}</mo><msub parentName="mrow"><mi parentName="msub">{`d`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow" {...{
                                "stretchy": "false"
                              }}>{`(`}</mo><mi parentName="mrow" {...{
                                "mathvariant": "bold"
                              }}>{`v`}</mi><mo parentName="mrow" {...{
                                "separator": "true"
                              }}>{`,`}</mo><mi parentName="mrow" {...{
                                "mathvariant": "bold"
                              }}>{`c`}</mi><mo parentName="mrow" {...{
                                "stretchy": "false"
                              }}>{`)`}</mo></mrow><mn parentName="mfrac">{`3`}</mn></mfrac></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><msub parentName="mstyle"><mi parentName="msub" {...{
                            "mathvariant": "bold"
                          }}>{`X`}</mi><mtext parentName="msub">{`medoid`}</mtext></msub></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mi parentName="mrow" {...{
                            "mathvariant": "bold"
                          }}>{`c`}</mi></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><msub parentName="mstyle"><mi parentName="msub" {...{
                            "mathvariant": "bold"
                          }}>{`X`}</mi><mtext parentName="msub">{`medoid`}</mtext></msub></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`[`}</mo><mtable parentName="mrow" {...{
                              "rowspacing": "0.16em",
                              "columnalign": "center center center",
                              "columnspacing": "1em"
                            }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`9`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`]`}</mo></mrow></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{aligned}

\\mathbf{X} &= 
\\begin{bmatrix}
4  & 3 & 2 \\\\
11 & 3 & 1 \\\\
9  & 3 & 3 \\\\
\\end{bmatrix}

\\\\[2em]

\\mathbf{X} &= 
\\begin{bmatrix}
\\mathbf{a} \\\\
\\mathbf{b} \\\\
\\mathbf{c} \\\\
\\end{bmatrix}

\\\\[2em]

\\mathbf{X}_{\\text{medoid}} &= \\underset{\\mathbf{v} \\in \\mathbf{X}}{\\argmin}\\frac{\\sum_{\\mathbf{x}_{i} \\in \\mathbf{X}} d(\\mathbf{v}, \\mathbf{x}_{i})}{\\vert X \\vert}

\\\\[2em]

\\mathbf{X}_{\\text{medoid}} &= \\underset{\\mathbf{v} \\in \\mathbf{X}}{\\argmin}\\frac{d_{2}(\\mathbf{v}, \\mathbf{a}) + d_{2}(\\mathbf{v}, \\mathbf{b}) + d_{2}(\\mathbf{v}, \\mathbf{c})}{3}

\\\\[2em]

\\mathbf{X}_{\\text{medoid}} &= \\mathbf{c}

\\\\[2em]

\\mathbf{X}_{\\text{medoid}} &= \\begin{bmatrix}
9  & 3 & 3 \\\\
\\end{bmatrix}

\\end{aligned}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "22.7169em",
                  "verticalAlign": "-11.1084em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "11.6084em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-13.6084em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathbf"
                              }}>{`X`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-8.8984em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathbf"
                              }}>{`X`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.6986em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathbf"
                                }}>{`X`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3361em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord text mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`medoid`}</span></span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-0.6116em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathbf"
                                }}>{`X`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3361em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord text mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`medoid`}</span></span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "2.8884em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathbf"
                                }}>{`X`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3361em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord text mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`medoid`}</span></span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "6.3984em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathbf"
                                }}>{`X`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3361em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord text mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`medoid`}</span></span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "11.1084em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "col-align-l"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "11.6084em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-13.6084em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "minner"
                              }}><span parentName="span" {...{
                                  "className": "mopen"
                                }}><span parentName="span" {...{
                                    "className": "delimsizing mult"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-t vlist-t2"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "2.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "style": {
                                              "top": "-2.25em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "delimsizinginner delim-size4"
                                            }}><span parentName="span">{`⎣`}</span></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-3.397em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "style": {
                                                "height": "0.016em",
                                                "width": "0.6667em"
                                              }
                                            }}><svg parentName="span" {...{
                                                "xmlns": "http://www.w3.org/2000/svg",
                                                "width": "0.6667em",
                                                "height": "0.016em",
                                                "style": {
                                                  "width": "0.6667em"
                                                },
                                                "viewBox": "0 0 666.67 16",
                                                "preserveAspectRatio": "xMinYMin"
                                              }}><path parentName="svg" {...{
                                                  "d": "M319 0 H403 V16 H319z M319 0 H403 V16 H319z"
                                                }}></path></svg></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-4.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "delimsizinginner delim-size4"
                                            }}><span parentName="span">{`⎡`}</span></span></span></span><span parentName="span" {...{
                                          "className": "vlist-s"
                                        }}>{`​`}</span></span><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "1.55em"
                                          }
                                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mtable"
                                  }}><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "2.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-4.21em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`4`}</span></span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`11`}</span></span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-1.81em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`9`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "1.55em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "2.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-4.21em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`3`}</span></span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`3`}</span></span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-1.81em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`3`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "1.55em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "2.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-4.21em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`2`}</span></span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`1`}</span></span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-1.81em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`3`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "1.55em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose"
                                }}><span parentName="span" {...{
                                    "className": "delimsizing mult"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-t vlist-t2"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "2.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "style": {
                                              "top": "-2.25em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "delimsizinginner delim-size4"
                                            }}><span parentName="span">{`⎦`}</span></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-3.397em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "style": {
                                                "height": "0.016em",
                                                "width": "0.6667em"
                                              }
                                            }}><svg parentName="span" {...{
                                                "xmlns": "http://www.w3.org/2000/svg",
                                                "width": "0.6667em",
                                                "height": "0.016em",
                                                "style": {
                                                  "width": "0.6667em"
                                                },
                                                "viewBox": "0 0 666.67 16",
                                                "preserveAspectRatio": "xMinYMin"
                                              }}><path parentName="svg" {...{
                                                  "d": "M263 0 H347 V16 H263z M263 0 H347 V16 H263z"
                                                }}></path></svg></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-4.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "delimsizinginner delim-size4"
                                            }}><span parentName="span">{`⎤`}</span></span></span></span><span parentName="span" {...{
                                          "className": "vlist-s"
                                        }}>{`​`}</span></span><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "1.55em"
                                          }
                                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-8.8984em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "minner"
                              }}><span parentName="span" {...{
                                  "className": "mopen"
                                }}><span parentName="span" {...{
                                    "className": "delimsizing mult"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-t vlist-t2"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "2.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "style": {
                                              "top": "-2.25em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "delimsizinginner delim-size4"
                                            }}><span parentName="span">{`⎣`}</span></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-3.397em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "style": {
                                                "height": "0.016em",
                                                "width": "0.6667em"
                                              }
                                            }}><svg parentName="span" {...{
                                                "xmlns": "http://www.w3.org/2000/svg",
                                                "width": "0.6667em",
                                                "height": "0.016em",
                                                "style": {
                                                  "width": "0.6667em"
                                                },
                                                "viewBox": "0 0 666.67 16",
                                                "preserveAspectRatio": "xMinYMin"
                                              }}><path parentName="svg" {...{
                                                  "d": "M319 0 H403 V16 H319z M319 0 H403 V16 H319z"
                                                }}></path></svg></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-4.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "delimsizinginner delim-size4"
                                            }}><span parentName="span">{`⎡`}</span></span></span></span><span parentName="span" {...{
                                          "className": "vlist-s"
                                        }}>{`​`}</span></span><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "1.55em"
                                          }
                                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mtable"
                                  }}><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "2.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-4.21em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mathbf"
                                                }}>{`a`}</span></span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mathbf"
                                                }}>{`b`}</span></span></span><span parentName="span" {...{
                                              "style": {
                                                "top": "-1.81em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mathbf"
                                                }}>{`c`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "1.55em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose"
                                }}><span parentName="span" {...{
                                    "className": "delimsizing mult"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-t vlist-t2"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "2.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "style": {
                                              "top": "-2.25em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "delimsizinginner delim-size4"
                                            }}><span parentName="span">{`⎦`}</span></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-3.397em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "style": {
                                                "height": "0.016em",
                                                "width": "0.6667em"
                                              }
                                            }}><svg parentName="span" {...{
                                                "xmlns": "http://www.w3.org/2000/svg",
                                                "width": "0.6667em",
                                                "height": "0.016em",
                                                "style": {
                                                  "width": "0.6667em"
                                                },
                                                "viewBox": "0 0 666.67 16",
                                                "preserveAspectRatio": "xMinYMin"
                                              }}><path parentName="svg" {...{
                                                  "d": "M263 0 H347 V16 H263z M263 0 H347 V16 H263z"
                                                }}></path></svg></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-4.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3.155em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "delimsizinginner delim-size4"
                                            }}><span parentName="span">{`⎤`}</span></span></span></span><span parentName="span" {...{
                                          "className": "vlist-s"
                                        }}>{`​`}</span></span><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "1.55em"
                                          }
                                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.6986em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mop op-limits"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.6679em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.1593em",
                                            "marginLeft": "0em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "3em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathbf mtight",
                                                "style": {
                                                  "marginRight": "0.01597em"
                                                }
                                              }}>{`v`}</span><span parentName="span" {...{
                                                "className": "mrel mtight"
                                              }}>{`∈`}</span><span parentName="span" {...{
                                                "className": "mord mathbf mtight"
                                              }}>{`X`}</span></span></span></span><span parentName="span" {...{
                                          "style": {
                                            "top": "-3em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "3em"
                                            }
                                          }}></span><span parentName="span"><span parentName="span" {...{
                                              "className": "mop"
                                            }}><span parentName="span" {...{
                                                "className": "mop"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mathrm",
                                                  "style": {
                                                    "marginRight": "0.01389em"
                                                  }
                                                }}>{`arg`}</span><span parentName="span" {...{
                                                  "className": "mspace",
                                                  "style": {
                                                    "marginRight": "0.1667em"
                                                  }
                                                }}></span><span parentName="span" {...{
                                                  "className": "mord mathrm"
                                                }}>{`min`}</span></span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.9681em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mopen nulldelimiter"
                                }}></span><span parentName="span" {...{
                                  "className": "mfrac"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "1.5398em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.314em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "3em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "mord"
                                          }}><span parentName="span" {...{
                                              "className": "mord"
                                            }}>{`∣`}</span><span parentName="span" {...{
                                              "className": "mord mathnormal",
                                              "style": {
                                                "marginRight": "0.07847em"
                                              }
                                            }}>{`X`}</span><span parentName="span" {...{
                                              "className": "mord"
                                            }}>{`∣`}</span></span></span><span parentName="span" {...{
                                          "style": {
                                            "top": "-3.23em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "3em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "frac-line",
                                            "style": {
                                              "borderBottomWidth": "0.04em"
                                            }
                                          }}></span></span><span parentName="span" {...{
                                          "style": {
                                            "top": "-3.7898em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "3em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "mord"
                                          }}><span parentName="span" {...{
                                              "className": "mop"
                                            }}><span parentName="span" {...{
                                                "className": "mop op-symbol small-op",
                                                "style": {
                                                  "position": "relative",
                                                  "top": "0em"
                                                }
                                              }}>{`∑`}</span><span parentName="span" {...{
                                                "className": "msupsub"
                                              }}><span parentName="span" {...{
                                                  "className": "vlist-t vlist-t2"
                                                }}><span parentName="span" {...{
                                                    "className": "vlist-r"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist",
                                                      "style": {
                                                        "height": "0.1806em"
                                                      }
                                                    }}><span parentName="span" {...{
                                                        "style": {
                                                          "top": "-2.4003em",
                                                          "marginLeft": "0em",
                                                          "marginRight": "0.05em"
                                                        }
                                                      }}><span parentName="span" {...{
                                                          "className": "pstrut",
                                                          "style": {
                                                            "height": "2.7em"
                                                          }
                                                        }}></span><span parentName="span" {...{
                                                          "className": "sizing reset-size6 size3 mtight"
                                                        }}><span parentName="span" {...{
                                                            "className": "mord mtight"
                                                          }}><span parentName="span" {...{
                                                              "className": "mord mtight"
                                                            }}><span parentName="span" {...{
                                                                "className": "mord mathbf mtight"
                                                              }}>{`x`}</span><span parentName="span" {...{
                                                                "className": "msupsub"
                                                              }}><span parentName="span" {...{
                                                                  "className": "vlist-t vlist-t2"
                                                                }}><span parentName="span" {...{
                                                                    "className": "vlist-r"
                                                                  }}><span parentName="span" {...{
                                                                      "className": "vlist",
                                                                      "style": {
                                                                        "height": "0.3281em"
                                                                      }
                                                                    }}><span parentName="span" {...{
                                                                        "style": {
                                                                          "top": "-2.357em",
                                                                          "marginLeft": "0em",
                                                                          "marginRight": "0.0714em"
                                                                        }
                                                                      }}><span parentName="span" {...{
                                                                          "className": "pstrut",
                                                                          "style": {
                                                                            "height": "2.5em"
                                                                          }
                                                                        }}></span><span parentName="span" {...{
                                                                          "className": "sizing reset-size3 size1 mtight"
                                                                        }}><span parentName="span" {...{
                                                                            "className": "mord mtight"
                                                                          }}><span parentName="span" {...{
                                                                              "className": "mord mathnormal mtight"
                                                                            }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                                                                      "className": "vlist-s"
                                                                    }}>{`​`}</span></span><span parentName="span" {...{
                                                                    "className": "vlist-r"
                                                                  }}><span parentName="span" {...{
                                                                      "className": "vlist",
                                                                      "style": {
                                                                        "height": "0.143em"
                                                                      }
                                                                    }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                                              "className": "mrel mtight"
                                                            }}>{`∈`}</span><span parentName="span" {...{
                                                              "className": "mord mathbf mtight"
                                                            }}>{`X`}</span></span></span></span></span><span parentName="span" {...{
                                                      "className": "vlist-s"
                                                    }}>{`​`}</span></span><span parentName="span" {...{
                                                    "className": "vlist-r"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist",
                                                      "style": {
                                                        "height": "0.3998em"
                                                      }
                                                    }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                              "className": "mspace",
                                              "style": {
                                                "marginRight": "0.1667em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "mord mathnormal"
                                            }}>{`d`}</span><span parentName="span" {...{
                                              "className": "mopen"
                                            }}>{`(`}</span><span parentName="span" {...{
                                              "className": "mord mathbf",
                                              "style": {
                                                "marginRight": "0.01597em"
                                              }
                                            }}>{`v`}</span><span parentName="span" {...{
                                              "className": "mpunct"
                                            }}>{`,`}</span><span parentName="span" {...{
                                              "className": "mspace",
                                              "style": {
                                                "marginRight": "0.1667em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "mord"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathbf"
                                              }}>{`x`}</span><span parentName="span" {...{
                                                "className": "msupsub"
                                              }}><span parentName="span" {...{
                                                  "className": "vlist-t vlist-t2"
                                                }}><span parentName="span" {...{
                                                    "className": "vlist-r"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist",
                                                      "style": {
                                                        "height": "0.3117em"
                                                      }
                                                    }}><span parentName="span" {...{
                                                        "style": {
                                                          "top": "-2.55em",
                                                          "marginLeft": "0em",
                                                          "marginRight": "0.05em"
                                                        }
                                                      }}><span parentName="span" {...{
                                                          "className": "pstrut",
                                                          "style": {
                                                            "height": "2.7em"
                                                          }
                                                        }}></span><span parentName="span" {...{
                                                          "className": "sizing reset-size6 size3 mtight"
                                                        }}><span parentName="span" {...{
                                                            "className": "mord mtight"
                                                          }}><span parentName="span" {...{
                                                              "className": "mord mathnormal mtight"
                                                            }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                                                      "className": "vlist-s"
                                                    }}>{`​`}</span></span><span parentName="span" {...{
                                                    "className": "vlist-r"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist",
                                                      "style": {
                                                        "height": "0.15em"
                                                      }
                                                    }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                              "className": "mclose"
                                            }}>{`)`}</span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.936em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose nulldelimiter"
                                }}></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-0.6116em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mop op-limits"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.6679em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.1593em",
                                            "marginLeft": "0em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "3em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathbf mtight",
                                                "style": {
                                                  "marginRight": "0.01597em"
                                                }
                                              }}>{`v`}</span><span parentName="span" {...{
                                                "className": "mrel mtight"
                                              }}>{`∈`}</span><span parentName="span" {...{
                                                "className": "mord mathbf mtight"
                                              }}>{`X`}</span></span></span></span><span parentName="span" {...{
                                          "style": {
                                            "top": "-3em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "3em"
                                            }
                                          }}></span><span parentName="span"><span parentName="span" {...{
                                              "className": "mop"
                                            }}><span parentName="span" {...{
                                                "className": "mop"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mathrm",
                                                  "style": {
                                                    "marginRight": "0.01389em"
                                                  }
                                                }}>{`arg`}</span><span parentName="span" {...{
                                                  "className": "mspace",
                                                  "style": {
                                                    "marginRight": "0.1667em"
                                                  }
                                                }}></span><span parentName="span" {...{
                                                  "className": "mord mathrm"
                                                }}>{`min`}</span></span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.9681em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mopen nulldelimiter"
                                }}></span><span parentName="span" {...{
                                  "className": "mfrac"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "1.427em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.314em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "3em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "mord"
                                          }}><span parentName="span" {...{
                                              "className": "mord"
                                            }}>{`3`}</span></span></span><span parentName="span" {...{
                                          "style": {
                                            "top": "-3.23em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "3em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "frac-line",
                                            "style": {
                                              "borderBottomWidth": "0.04em"
                                            }
                                          }}></span></span><span parentName="span" {...{
                                          "style": {
                                            "top": "-3.677em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "3em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "mord"
                                          }}><span parentName="span" {...{
                                              "className": "mord"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal"
                                              }}>{`d`}</span><span parentName="span" {...{
                                                "className": "msupsub"
                                              }}><span parentName="span" {...{
                                                  "className": "vlist-t vlist-t2"
                                                }}><span parentName="span" {...{
                                                    "className": "vlist-r"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist",
                                                      "style": {
                                                        "height": "0.3011em"
                                                      }
                                                    }}><span parentName="span" {...{
                                                        "style": {
                                                          "top": "-2.55em",
                                                          "marginLeft": "0em",
                                                          "marginRight": "0.05em"
                                                        }
                                                      }}><span parentName="span" {...{
                                                          "className": "pstrut",
                                                          "style": {
                                                            "height": "2.7em"
                                                          }
                                                        }}></span><span parentName="span" {...{
                                                          "className": "sizing reset-size6 size3 mtight"
                                                        }}><span parentName="span" {...{
                                                            "className": "mord mtight"
                                                          }}><span parentName="span" {...{
                                                              "className": "mord mtight"
                                                            }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                                      "className": "vlist-s"
                                                    }}>{`​`}</span></span><span parentName="span" {...{
                                                    "className": "vlist-r"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist",
                                                      "style": {
                                                        "height": "0.15em"
                                                      }
                                                    }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                              "className": "mopen"
                                            }}>{`(`}</span><span parentName="span" {...{
                                              "className": "mord mathbf",
                                              "style": {
                                                "marginRight": "0.01597em"
                                              }
                                            }}>{`v`}</span><span parentName="span" {...{
                                              "className": "mpunct"
                                            }}>{`,`}</span><span parentName="span" {...{
                                              "className": "mspace",
                                              "style": {
                                                "marginRight": "0.1667em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "mord mathbf"
                                            }}>{`a`}</span><span parentName="span" {...{
                                              "className": "mclose"
                                            }}>{`)`}</span><span parentName="span" {...{
                                              "className": "mspace",
                                              "style": {
                                                "marginRight": "0.2222em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "mbin"
                                            }}>{`+`}</span><span parentName="span" {...{
                                              "className": "mspace",
                                              "style": {
                                                "marginRight": "0.2222em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "mord"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal"
                                              }}>{`d`}</span><span parentName="span" {...{
                                                "className": "msupsub"
                                              }}><span parentName="span" {...{
                                                  "className": "vlist-t vlist-t2"
                                                }}><span parentName="span" {...{
                                                    "className": "vlist-r"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist",
                                                      "style": {
                                                        "height": "0.3011em"
                                                      }
                                                    }}><span parentName="span" {...{
                                                        "style": {
                                                          "top": "-2.55em",
                                                          "marginLeft": "0em",
                                                          "marginRight": "0.05em"
                                                        }
                                                      }}><span parentName="span" {...{
                                                          "className": "pstrut",
                                                          "style": {
                                                            "height": "2.7em"
                                                          }
                                                        }}></span><span parentName="span" {...{
                                                          "className": "sizing reset-size6 size3 mtight"
                                                        }}><span parentName="span" {...{
                                                            "className": "mord mtight"
                                                          }}><span parentName="span" {...{
                                                              "className": "mord mtight"
                                                            }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                                      "className": "vlist-s"
                                                    }}>{`​`}</span></span><span parentName="span" {...{
                                                    "className": "vlist-r"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist",
                                                      "style": {
                                                        "height": "0.15em"
                                                      }
                                                    }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                              "className": "mopen"
                                            }}>{`(`}</span><span parentName="span" {...{
                                              "className": "mord mathbf",
                                              "style": {
                                                "marginRight": "0.01597em"
                                              }
                                            }}>{`v`}</span><span parentName="span" {...{
                                              "className": "mpunct"
                                            }}>{`,`}</span><span parentName="span" {...{
                                              "className": "mspace",
                                              "style": {
                                                "marginRight": "0.1667em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "mord mathbf"
                                            }}>{`b`}</span><span parentName="span" {...{
                                              "className": "mclose"
                                            }}>{`)`}</span><span parentName="span" {...{
                                              "className": "mspace",
                                              "style": {
                                                "marginRight": "0.2222em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "mbin"
                                            }}>{`+`}</span><span parentName="span" {...{
                                              "className": "mspace",
                                              "style": {
                                                "marginRight": "0.2222em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "mord"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal"
                                              }}>{`d`}</span><span parentName="span" {...{
                                                "className": "msupsub"
                                              }}><span parentName="span" {...{
                                                  "className": "vlist-t vlist-t2"
                                                }}><span parentName="span" {...{
                                                    "className": "vlist-r"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist",
                                                      "style": {
                                                        "height": "0.3011em"
                                                      }
                                                    }}><span parentName="span" {...{
                                                        "style": {
                                                          "top": "-2.55em",
                                                          "marginLeft": "0em",
                                                          "marginRight": "0.05em"
                                                        }
                                                      }}><span parentName="span" {...{
                                                          "className": "pstrut",
                                                          "style": {
                                                            "height": "2.7em"
                                                          }
                                                        }}></span><span parentName="span" {...{
                                                          "className": "sizing reset-size6 size3 mtight"
                                                        }}><span parentName="span" {...{
                                                            "className": "mord mtight"
                                                          }}><span parentName="span" {...{
                                                              "className": "mord mtight"
                                                            }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                                      "className": "vlist-s"
                                                    }}>{`​`}</span></span><span parentName="span" {...{
                                                    "className": "vlist-r"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist",
                                                      "style": {
                                                        "height": "0.15em"
                                                      }
                                                    }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                              "className": "mopen"
                                            }}>{`(`}</span><span parentName="span" {...{
                                              "className": "mord mathbf",
                                              "style": {
                                                "marginRight": "0.01597em"
                                              }
                                            }}>{`v`}</span><span parentName="span" {...{
                                              "className": "mpunct"
                                            }}>{`,`}</span><span parentName="span" {...{
                                              "className": "mspace",
                                              "style": {
                                                "marginRight": "0.1667em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "mord mathbf"
                                            }}>{`c`}</span><span parentName="span" {...{
                                              "className": "mclose"
                                            }}>{`)`}</span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.686em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose nulldelimiter"
                                }}></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "2.8884em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathbf"
                              }}>{`c`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "6.3984em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "4.05em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "minner"
                              }}><span parentName="span" {...{
                                  "className": "mopen delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size1"
                                  }}>{`[`}</span></span><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mtable"
                                  }}><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`9`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`3`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`3`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size1"
                                  }}>{`]`}</span></span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "11.1084em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p><img parentName="p" {...{
        "src": "/929ff52c589d0844e8f76c8549dbedc3/3d-medoid-example.gif",
        "alt": "\"Medoid (visualized).\"",
        "title": "Medoid in relation to other points."
      }}></img></p>
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <p>{`You now know some of the key concepts behind clustering.  Let's practice ...`}</p>
    {
      /* ## Related */
    }
    <h1 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h1>
    <ul>
      <li parentName="ul">{`When is the medoid a better measure of centrality than a matrice's centroid?`}</li>
      <li parentName="ul">{`Is it more efficient to compute the centroid or medoid?`}</li>
      <li parentName="ul">{`Calculate the centroid for the following matrix:`}</li>
    </ul>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow" {...{
                    "fence": "true"
                  }}>{`[`}</mo><mtable parentName="mrow" {...{
                    "rowspacing": "0.16em",
                    "columnalign": "center center center",
                    "columnspacing": "1em"
                  }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mrow parentName="mstyle"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`4`}</mn></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mrow parentName="mstyle"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mn parentName="mstyle">{`22`}</mn></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mn parentName="mstyle">{`4`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mrow parentName="mstyle"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mn parentName="mstyle">{`8`}</mn></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mn parentName="mstyle">{`5`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mrow parentName="mstyle"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mn parentName="mstyle">{`73`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                    "fence": "true"
                  }}>{`]`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{bmatrix}
-4 & -1 & 22 \\\\
 4 & -1 &  8 \\\\
 5 & -1 & 73 \\\\
\\end{bmatrix}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3.6em",
                  "verticalAlign": "-1.55em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎣`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M319 0 H403 V16 H319z M319 0 H403 V16 H319z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎡`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mtable"
                  }}><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "2.05em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-4.21em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`−`}</span><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`4`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`4`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-1.81em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`5`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.55em"
                            }
                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "0.5em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "0.5em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "2.05em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-4.21em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`−`}</span><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`1`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`−`}</span><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`1`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-1.81em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`−`}</span><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`1`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.55em"
                            }
                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "0.5em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "0.5em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "2.05em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-4.21em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`22`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`8`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-1.81em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`73`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.55em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎦`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M263 0 H347 V16 H263z M263 0 H347 V16 H263z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎤`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <ul>
      <li parentName="ul">{`Calculate the medoid for the following matrix:`}</li>
    </ul>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow" {...{
                    "fence": "true"
                  }}>{`[`}</mo><mtable parentName="mrow" {...{
                    "rowspacing": "0.16em",
                    "columnalign": "center center center",
                    "columnspacing": "1em"
                  }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mrow parentName="mstyle"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`4`}</mn></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mrow parentName="mstyle"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mn parentName="mstyle">{`22`}</mn></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mn parentName="mstyle">{`4`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mrow parentName="mstyle"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mn parentName="mstyle">{`8`}</mn></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mn parentName="mstyle">{`5`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mrow parentName="mstyle"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                          "scriptlevel": "0",
                          "displaystyle": "false"
                        }}><mn parentName="mstyle">{`73`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                    "fence": "true"
                  }}>{`]`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{bmatrix}
-4 & -1 & 22 \\\\
 4 & -1 &  8 \\\\
 5 & -1 & 73 \\\\
\\end{bmatrix}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3.6em",
                  "verticalAlign": "-1.55em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎣`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M319 0 H403 V16 H319z M319 0 H403 V16 H319z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎡`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mtable"
                  }}><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "2.05em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-4.21em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`−`}</span><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`4`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`4`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-1.81em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`5`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.55em"
                            }
                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "0.5em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "0.5em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "2.05em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-4.21em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`−`}</span><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`1`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`−`}</span><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`1`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-1.81em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`−`}</span><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`1`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.55em"
                            }
                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "0.5em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "0.5em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "2.05em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-4.21em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`22`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`8`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-1.81em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`73`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.55em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎦`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M263 0 H347 V16 H263z M263 0 H347 V16 H263z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎤`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <ul>
      <li parentName="ul">{`Given `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`X`}</mi><mo parentName="mrow">{`=`}</mo><mo parentName="mrow">{`<`}</mo><mn parentName="mrow">{`0`}</mn><mo parentName="mrow" {...{
                      "separator": "true"
                    }}>{`,`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow" {...{
                      "separator": "true"
                    }}>{`,`}</mo><mn parentName="mrow">{`2`}</mn><mo parentName="mrow" {...{
                      "separator": "true"
                    }}>{`,`}</mo><mn parentName="mrow">{`3`}</mn><mo parentName="mrow" {...{
                      "separator": "true"
                    }}>{`,`}</mo><mn parentName="mrow">{`4`}</mn><mo parentName="mrow" {...{
                      "separator": "true"
                    }}>{`,`}</mo><mn parentName="mrow">{`5`}</mn><mo parentName="mrow">{`>`}</mo></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`X = <0,1,2,3,4,5>`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.7224em",
                    "verticalAlign": "-0.0391em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.07847em"
                  }
                }}>{`X`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mrel"
                }}>{`=<`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.8389em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`0`}</span><span parentName="span" {...{
                  "className": "mpunct"
                }}>{`,`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.1667em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`1`}</span><span parentName="span" {...{
                  "className": "mpunct"
                }}>{`,`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.1667em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`2`}</span><span parentName="span" {...{
                  "className": "mpunct"
                }}>{`,`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.1667em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`3`}</span><span parentName="span" {...{
                  "className": "mpunct"
                }}>{`,`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.1667em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`4`}</span><span parentName="span" {...{
                  "className": "mpunct"
                }}>{`,`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.1667em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`5`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mrel"
                }}>{`>`}</span></span></span></span></span>{`, what is the `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`arg min`}</mi><mo parentName="mrow">{`⁡`}</mo></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`\\argmin`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.8623em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mop"
                }}><span parentName="span" {...{
                    "className": "mord mathrm",
                    "style": {
                      "marginRight": "0.01389em"
                    }
                  }}>{`arg`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.1667em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathrm"
                  }}>{`min`}</span></span></span></span></span></span>{` for the following function?`}</li>
    </ul>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><munder parentName="mrow"><mrow parentName="munder"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`arg min`}</mi><mo parentName="mrow">{`⁡`}</mo></mrow><mrow parentName="munder"><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`X`}</mi></mrow></munder><mi parentName="mrow">{`f`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`x`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><msup parentName="mrow"><mi parentName="msup">{`x`}</mi><mn parentName="msup">{`3`}</mn></msup></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\argmin_{x \\in X} f(x) = x^{3}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.7161em",
                  "verticalAlign": "-0.9661em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop op-limits"
              }}><span parentName="span" {...{
                  "className": "vlist-t vlist-t2"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "0.6679em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-2.1612em",
                          "marginLeft": "0em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "sizing reset-size6 size3 mtight"
                        }}><span parentName="span" {...{
                            "className": "mord mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`x`}</span><span parentName="span" {...{
                              "className": "mrel mtight"
                            }}>{`∈`}</span><span parentName="span" {...{
                              "className": "mord mathnormal mtight",
                              "style": {
                                "marginRight": "0.07847em"
                              }
                            }}>{`X`}</span></span></span></span><span parentName="span" {...{
                        "style": {
                          "top": "-3em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span"><span parentName="span" {...{
                            "className": "mop"
                          }}><span parentName="span" {...{
                              "className": "mord mathrm",
                              "style": {
                                "marginRight": "0.01389em"
                              }
                            }}>{`arg`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.1667em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord mathrm"
                            }}>{`min`}</span></span></span></span></span><span parentName="span" {...{
                      "className": "vlist-s"
                    }}>{`​`}</span></span><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "0.9661em"
                      }
                    }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10764em"
                }
              }}>{`f`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8641em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`3`}</span></span></span></span></span></span></span></span></span></span></span></span></span></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      